@font-face {
font-family: 'Aeonik';
src: url(/_next/static/media/90d8e5e54c14ff38-s.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'Aeonik';
src: url(/_next/static/media/072909a47a84c7b4-s.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: 'Aeonik';
src: url(/_next/static/media/ee8d7785de3b3294-s.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'Aeonik';
src: url(/_next/static/media/9384258b9ebc842c-s.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: 'Aeonik';
src: url(/_next/static/media/237722a59de2232c-s.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'Aeonik';
src: url(/_next/static/media/c8342cf95d38228c-s.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: 'Aeonik';
src: url(/_next/static/media/b25f8e7fef3787a8-s.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'Aeonik';
src: url(/_next/static/media/8ebe27464e05f991-s.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: 'Aeonik';
src: url(/_next/static/media/73c180f13e457890-s.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: 'Aeonik';
src: url(/_next/static/media/8a4bcd14420c8202-s.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: italic;
}@font-face {font-family: 'Aeonik Fallback';src: local("Arial");ascent-override: 87.83%;descent-override: 20.15%;line-gap-override: 8.80%;size-adjust: 102.24%
}.__className_8570a8 {font-family: 'Aeonik', 'Aeonik Fallback'
}.__variable_8570a8 {--font-aeonik: 'Aeonik', 'Aeonik Fallback'
}

